/* :root {
  --background-image-url: url("https://abmrc.com/wp-content/uploads/2023/03/Smiles.jpeg");
} */
/* Background styles */
.background-container {
  background-image: url("./resp.png");
  justify-items: stretch;
}

.decrypt-container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  /* padding: 20px; */
  font-family: Arial, sans-serif;
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(224, 226, 230, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  margin: 25px;
  border-radius: 25px;
  max-width: 80vw;
  /* height: 94vh; */
}
.upload-container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  padding: 20px;
  font-family: Arial, sans-serif;
  backdrop-filter: blur(5px) saturate(150%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  /* background-color: rgba(224, 226, 230, 0.75); */
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  margin: 25px;
  border-radius: 25px;
  height: 75vh;
  width: 75vw;
  justify-content: center;
  border-style: dashed;
  border-color: black;
  display: flex;
  cursor: pointer;
}
.header {
  margin: 10px;
  align-items: center;
}
.details {
  margin: 10px;
}

.header h3 {
  font-size: 20px;
  color: #333;
}
.rdrDefinedRangesWrapper {
  max-width: 20%;
}
.header span {
  margin: 3px 0 0;
  font-size: 16px;
  color: #777;
}

.content {
  display: flex;
  gap: 20px;
  border-radius: 25px;
  flex-direction: column; /* Stacks items vertically by default */
  padding: 10px; /* Default padding for all screens */
  font-size: small;
}

.date-picker {
  flex: 1;
  border-radius: 25px;
  max-width: 100%; /* Full width by default */
}

.charts-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  max-height: 60vh; /* Default height for smaller screens */
  width: 100%;
}

@media (min-width: 600px) and (max-width: 1199px) {
  .content {
    flex-direction: row; /* Horizontal layout */
    gap: 15px; /* Adjust gap for medium screens */
  }

  .date-picker {
    flex: 1;
    max-width: 40%; /* Date picker takes up 40% of the width */
  }

  .charts-container {
    flex: 2;
    max-height: 70vh; /* Adjust height for medium screens */
    padding: 15px; /* Adjust padding for medium screens */
  }
}
@media (min-width: 600px) and (max-width: 1199px) {
  .content {
    flex-direction: row; /* Horizontal layout */
    gap: 15px; /* Adjust gap for medium screens */
  }

  .date-picker {
    flex: 1;
    max-width: 40%; /* Date picker takes up 40% of the width */
  }

  .charts-container {
    flex: 2;
    max-height: 70vh; /* Adjust height for medium screens */
    padding: 15px; /* Adjust padding for medium screens */
  }
}
@media (min-width: 1200px) {
  .content {
    flex-direction: row; /* Horizontal layout */
    gap: 20px; /* Larger gap for bigger screens */
  }

  .date-picker {
    flex: 1;
    max-width: 30%; /* Date picker takes up 30% of the width */
  }

  .charts-container {
    flex: 2;
    max-height: 90vh; /* Max height for larger screens */
    padding: 20px; /* Larger padding for desktops */
  }
}

.chart-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-card h4 {
  margin: 0 0 10px;
  font-size: 20px;
  color: #333;
}
/* Additional styles for tabs */

.navbar-container {
  position: relative;
}

.navbar {
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.nav-item {
  font-size: 19px;
  position: relative;
  z-index: 10;
  transition: color 0.3s ease;
}

.nav-item.active {
  color: #090406;
}

.active-indicator {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #636162;

  top: -15px;
  transition: left 0.3s ease;
}

/* .active-indicator.menu {
  left: 15%;
}

.active-indicator.inbox {
  left: 35%;
} */

/* .active-indicator.layers {
  left: 55%;
}

.active-indicator.layout {
  left: 75%;
}

.active-indicator.image {
  left: 95%;
} */

.tabs {
  display: flex;
  border-bottom: 2px solid #e5e7eb;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: #6b7280;
  transition: color 0.3s ease;
}

.tab:hover {
  /* color: #111827; */
}

.tab.active {
  color: #1d4ed8;
  border-bottom: 2px solid #1d4ed8;
}

.tab-content {
  margin-top: 20px;
}
.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed #007bff;
  border-radius: 5px;
  background-color: #f8f9fa;
  color: #007bff;
  font-family: Arial, sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-top: 20px;
}

.dropzone:hover {
  background-color: #e9ecef;
  border-color: #0056b3;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
}

.dropzone:focus {
  outline: none;
}

.uploadingcsv-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.uploadingcsv-heading {
  margin-bottom: 20px;
}

.file-upload-container {
  margin-bottom: 20px;
}

.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover {
  background-color: #0056b3;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background: linear-gradient(135deg, #7bd3eb, #ff8f1c, #c5e86c);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
  height: 100vh;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.logo {
  position: absolute;
  width: 100px;
  height: auto;
}

.logo-left {
  top: 20px;
  left: 20px;
}

.logo-right {
  top: 20px;
  right: 20px;
}

.graph-container {
  /* display: flex; */
  /* flex-direction: row; */
  /* gap: 5px; */
  /* padding: 5px; */
  /* align-items: center; */

  /* justify-content: center; */
}
.graph-instructions {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.graph-instructions h3 {
  color: #333;
  margin-bottom: 5px;
}
.graph-instructions p {
  color: #555;
  margin-bottom: 5px;
  font-size: 12px;
}
.graph-instructions ul {
  margin: 0;
  padding-left: 12px;
  color: #444;
}
.graph-instructions ul li {
  margin-bottom: 8px;
  font-size: 13px;
}
.graph-instructions ul li strong {
  color: #0073e6;
}
.plot-container {
  flex: 3;
  /* background-color: #f9f9f9; */
  padding: 8px;
  border-radius: 10px;

  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* max-width: 80%; */
}
.plot-item {
  background-color: #ffffff;
}

.sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 15%;
  transition: ease-in-out 1s;
}

.button-group {
  display: flex;
  flex-direction: column;
}

.custom-button {
  background-color: #5d605e;
  color: white;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #646464;
}

.report-list {
  overflow-y: scroll !important;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.report-item {
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.report-item img {
  /* width: 50%; */
  margin: 2px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  visibility: hidden;
}

.report-item:hover .image-overlay {
  visibility: visible;
}

.image-overlay .icon {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
}

.image-viewer img {
  max-width: 100%;
  max-height: 80vh;
  transition: transform 0.3s ease;
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  scale: 2;
  cursor: ew-resize;
  z-index: 10;
  padding: 20px 0px 20px 20px;
  color: black;
}


.custom-button {
  height: 38px;
  padding: 8px 24px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #4CA1AF;
  color: white;
  cursor: pointer;
}
.custom-button:hover{
  background-color: #58738e;

}

.button-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #94a5d2;
  border-radius: 50%;
  /* border-top-color: transparent; */
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  right: 105%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
}